<template>
  <div class="text-container">
    <p>
      Wir helfen Ihnen sich dem Thema Apotheken- oder besser Mitarbeiterführung
      zu nähern. Denn Sie als Inhaber einer Apotheke müssen dafür sorgen, dass
    </p>
    <ul>
      <li>Alle wissen, was Sache ist.</li>
      <li>Alle wollen, was Sache ist.</li>
      <li>Alle tun, was Sache ist.</li>
      <li>Alle koordiniert tun was Sache ist.</li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Sie zu den Themen Apothekenführung und Mitarbeiterführung.",
      },
    ],
  },
};
</script>